<template lang="pug">
	.info-widget
		span.label {{ label }}
		span.value {{ value }}
</template>

<script>
export default {
	name: "InfoWidget",
	props: {
		value: {
			type: [String, Number],
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
	}
}
</script>

<style lang="scss" scoped>
.info-widget {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	background-color: color(white);
	border-radius: 2px;
	border: 1px solid color(gray-400);
	padding: 24px;
  width: 302px;
  height: 134px;
}

.label {
  max-width: 230px;
  text-align: right;
}

.value {
  font-size: 24px;
  line-height: 16px;
  font-weight: 400;
}
</style>