<template lang="pug">
	div
		template(v-if="isVisibleData")
			.share.flex.justify-between
				template(v-if="isLoading")
					skeleton
				template(v-else)
					.card.flex.flex-col.items-center(v-for="(chart, i) in stackedBarChartData" :key="i")
						p {{chart.label}}
						BarChart.chart(:chartData="chart" :options="stackedBarChartOptions")

		.empty-filter(v-else) Пожалуйста, выберите значения фильтра

</template>

<script>
import { renameYandexByName } from '@/helpers/RenameYandexPokupki'
import Menu from '@/components/Menu/Menu.vue'
import BarChart from "@/components/Chart/BarChart"
import FilterInLocalStorage from '@/helpers/LocalStorage/Filter'
import Skeleton from '@/components/Nestle/Skeleton/Common.vue'

export default {
	name: "ShareOfTaxonomy",
	components: {
		Menu,
		BarChart,
		FilterInLocalStorage,
		Skeleton,
	},
	props: {
    excludeSku: {
      type: String,
      default: '',
    },
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},
		isVisibleData: {
			type: Boolean,
			default: false,
		},

		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
    subBrands: {
      type: String,
      default: '',
    },
		regions: {
			type: String,
			default: '',
		},
    seasonProducts: {
      type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			res: null,
			current: 1,
			limit: 20,
			offset: 0,
			isLoading: true,
		}
	},
	computed: {
		stackedBarChartOptions () {
			return {
				indexAxis: 'y',
				plugins: {
					datalabels: {
						display: function (context){
							return context.dataset.data[context.dataIndex] > 5
						},
						formatter: (item) => {
							return `${item}%`
						},
						color: 'white',
						backgroundColor: 'transparent',
						borderColor: 'transparent',
						font: {
							weight: 'bold',
							size: 10
						}
					},
				},
				responsive: true,
				scales: {
					x: {
						stacked: true,
						display: false
					},
					y: {
						stacked: true,
					}
				}
			}
		},
		stackedBarChartData() {
			if (!this.res) return
			const result = []

			if (this.res?.stores) {
				result.push({
					labels: this.res.stores.map(item=>item.store.name),
					label: 'Online Store',
					datasets: [
						{
							barPercentage: this.colSize,
							borderColor: '#93AF21',
							backgroundColor: '#93AF21',
							data: this.res.stores.map(item=>item.averagePercentage),
						},
						{
							barPercentage: this.colSize,
							borderColor: '#ADACAD',
							backgroundColor: '#ADACAD',
							data: this.res.stores.map(item=>100-item.averagePercentage),
						},
					]
				})
			}

			if (this.res?.category) {
				result.push({
					labels: this.res.category.map(item=>item.filter.name),
					label: 'Category',
					datasets: [
						{
							barPercentage: this.colSize,
							borderColor: '#93AF21',
							backgroundColor: '#93AF21',
							data: this.res.category.map(item=>item.averagePercentage),
						},
						{
							barPercentage: this.colSize,
							borderColor: '#ADACAD',
							backgroundColor: '#ADACAD',
							data: this.res.category.map(item=>100-item.averagePercentage),
						},
					]
				})
			}

			if (this.res?.brands) {
				result.push({
					labels: this.res.brands.map(item=>item.brand.name),
					label: 'Brand',
					datasets: [
						{
							barPercentage: this.colSize,
							borderColor: '#93AF21',
							backgroundColor: '#93AF21',
							data: this.res.brands.map(item=>item.averagePercentage),
						},
						{
							barPercentage: this.colSize,
							borderColor: '#ADACAD',
							backgroundColor: '#ADACAD',
							data: this.res.brands.map(item=>100-item.averagePercentage),
						},
					]
				})
			}

			if (this.res?.brands) {
				result.push({
					labels: this.res.subcategory.map(item=>item.category.name),
					label: 'Subcategory',
					datasets: [
						{
							barPercentage: this.colSize,
							borderColor: '#93AF21',
							backgroundColor: '#93AF21',
							data: this.res.subcategory.map(item=>item.averagePercentage),
						},
						{
							barPercentage: this.colSize,
							borderColor: '#ADACAD',
							backgroundColor: '#ADACAD',
							data: this.res.subcategory.map(item=>100-item.averagePercentage),
						},
					]
				})
			}

			return result
		},
		tableOptions() {
			return {
				sort: {field: 'name', order: 'desc'},
				columns: this.tableColumns,
				data: this.tableData,
			}
		},
	},
	methods: {
		async fetch() {
			this.$emit('showData')
			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
        subBrands: this.subBrands,
				regions: this.regions,
				category: this.group,
        seasonProducts: this.seasonProducts,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
        excludeSku: this.excludeSku,
			};
			try {
				this.isLoading = true

				const result = await this.$api.nestle.getShareOfTaxonomy(params)
				const renamedItems = renameYandexByName(result)
				this.res = renamedItems
			} catch (e) {
				console.log(e)
			} finally {
				this.isLoading = false
			}
		},
		colSize(context){
			return context.dataset.data.length <= 1 ? 1.23 : 1.2
		},
	},
	watch: {
		needUpdateResult: {
			immediate: true,
			async handler() {
				this.offset = 0
				this.current = 1
				await this.fetch()
			}
		},
		current: {
			handler() {
				this.offset = this.limit * (this.current-1)
				this.fetch()
			},
		},
	},

}
</script>

<style lang="scss" scoped>
.share {
	max-width: 1280px;
	margin: 0 auto;
	.chart, .card {
		width: 300px;
		height: 600px;
	}
	.card p{
		color: color(gray-700);
		font-weight: bold;
	}
	::v-deep.chart{
		margin: 0;
	}
}
.empty-filter {
	font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
</style>
