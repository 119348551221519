<template lang="pug">
  div
    template(v-if="isVisibleData")
      h2.mb-12.title Полная категорийная выдача
      p.mb-32.description Включает в себя мои бренды и конкурентов.

      button.download(@click="download") Download file

    .empty-filter(v-else) Пожалуйста, выберите значения фильтра
</template>

<script>

export default {
	name: "ProductDetails",
	props: {
    excludeSku: {
      type: String,
      default: '',
    },
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},
		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
    subBrands: {
      type: String,
      default: '',
    },
		regions: {
			type: String,
			default: '',
		},
		seasonProducts: {
      type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			link: '',
			isVisibleData: false,
		}
	},
	methods: {
		fetch() {
			const linkL = process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`

			const params = {
        excludeSku: this.excludeSku,
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
				regions: this.regions,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
			};
			this.link = `${linkL}dashboard/shareOfTaxonomy/productDetails/xlsx?regions=${params.regions}&stores=${params.stores}&brands=${params.brands}&categories=${params.categories}&dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`
			this.isVisibleData = true
		},


		async download() {
			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
        subBrands: this.subBrands,
				regions: this.regions,
        seasonProducts: this.seasonProducts,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
			};

			try {
				const image = await this.$api.nestle.getTaxonomyProductDetails(params)
				const fileURL = window.URL.createObjectURL(
					new Blob(
						[image], 
						{ type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8 " }
					)
				)
				const fileLink = document.createElement('a')

				fileLink.href = fileURL
				fileLink.setAttribute('download', 'report.xlsx')
				document.body.appendChild(fileLink)
				fileLink.click()
			} catch (error) {
				console.log(error)
			}
		},

	},
	watch: {
		needUpdateResult: {
			immediate: true,
			handler() {
				this.fetch()
			}
		},
	},

}
</script>

<style lang="scss" scoped>
.title {
  font-family: 'OpenSans';
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #000000;
}
.description {
  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
}
.download {
  border: 1px solid #424242;
  padding: 4px 8px;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  transition: .3s all ease;
  &:hover {
    background-color: #e2dddd;
  }
}
.empty-filter {
  font-size: 16px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
</style>
