<template lang="pug">
	div
		template(v-if="isVisibleData")
			.share.flex.justify-center
				ContainerForData(
					width="100%"
					:isLoading="isLoading"
					:titleVisible="false"
				)
					template(#data)
						template(v-if="isEmptyResult")
							.charts-list.flex.justify-between
								.charts(v-for="chart in stackedBarChartData")
									h2.chart-label {{ chart.label }}
									BarChart.chart(:chartData="chart" :options="stackedBarChartOptions")
						template(v-else)
							p.no-data Data not found. Please, change your filter params.

		.empty-filter(v-else) Пожалуйста, выберите значения фильт

</template>

<script>
import Menu from '@/components/Menu/Menu.vue'
import BarChart from "@/components/Chart/BarChart"
import ContainerForData from "@/components/Nestle/ContainerForData";
import CogOutline from 'vue-material-design-icons/CogOutline.vue'

export default {
	name: "ShareOfTaxonomy",
	components: {
		Menu,
		BarChart,
		ContainerForData,
		CogOutline
	},
	props: {
    excludeSku: {
      type: String,
      default: '',
    },
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},
		isVisibleData: {
			type: Boolean,
			default: false,
		},
		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
    subBrands: {
      type: String,
      default: '',
    },
		regions: {
			type: String,
			default: '',
		},
		seasonProducts: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			resp: null,
			current: 1,
			limit: 20,
			offset: 0,
			isLoading: false,
		}
	},
	computed: {
		isEmptyResult () {
			if (!this.resp) return
			return Object.keys(this.resp).reduce((acc, item) => acc+= this.resp[item].length, 0)
		},

		stackedBarChartOptions () {
			return {
				indexAxis: 'y',
				plugins: {
					datalabels: {
						display: true,
						color: '#D0CBCB',
						backgroundColor: 'transparent',
						borderColor: 'transparent',
						font: {
							weight: 'bold',
							size: 11
						},
						align: 'end',
						anchor: 'end',
					},
				},
				layout: {
					padding: {
						right: 40,
					},
				},
				responsive: true,
				scales: {
					x: {
						display: false
					}
				}
			}
		},
		stackedBarChartData() {
			if (!this.resp) return
			const result = []

			if (this.resp?.productNotRankedInTop50ByOnlineStore) {
				result.push({
					labels: this.resp.productNotRankedInTop50ByOnlineStore.map(item=>item.storeName),
					label: 'Product Not Ranked in Top 50 By Online Store',
					datasets: [
						{
							barPercentage: this.colSize,
							backgroundColor: '#D0CBCB',
							data: this.resp.productNotRankedInTop50ByOnlineStore.map(item=>item.value),
						}
					]
				})
			}

			if (this.resp?.productNotRankedInTop50ByCategory) {
				result.push({
					labels: this.resp.productNotRankedInTop50ByCategory.map(item=>item.filterName),
					label: 'Product Not Ranked in Top 50 By Category',
					datasets: [
						{
							barPercentage: this.colSize,
							backgroundColor: '#D0CBCB',
							data: this.resp.productNotRankedInTop50ByCategory.map(item=>item.value),
						}
					]
				})
			}

			if (this.resp?.productNotRankedInTop50ByBrand) {
				result.push({
					labels: this.resp.productNotRankedInTop50ByBrand.map(item=>item.brandName),
					label: 'Product Not Ranked in Top 50 By Brand',
					datasets: [
						{
							barPercentage: this.colSize,
							backgroundColor: '#D0CBCB',
							data: this.resp.productNotRankedInTop50ByBrand.map(item=>item.value),
						}
					]
				})
			}

			return result
		},
		tableOptions() {
			return {
				sort: {field: 'name', order: 'desc'},
				columns: this.tableColumns,
				data: this.tableData,
			}
		},
	},
	methods: {
		colSize(context){
			return context.dataset.data.length <= 1 ? 1.23 : 1.2
		},
		async fetch() {
			this.$emit('showData')
			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
        subBrands: this.subBrands,
				regions: this.regions,
				category: this.group,
				seasonProducts: this.seasonProducts,
        excludeSku: this.excludeSku,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
			};
			try {
				this.isLoading = true
				this.resp = await this.$api.nestle.getProductFails(params)
				if (this.resp) {
					console.log(this.resp)
				}
			} catch(error) {
				console.log(error)
			} finally {
				this.isLoading = false
			}
		},
	},
	watch: {
		needUpdateResult: {
			immediate: true,
			async handler() {
				console.log('NeedUpateResult')
				this.offset = 0
				this.current = 1
				await this.fetch()
			}
		},
		current: {
			handler() {
				this.offset = this.limit * (this.current-1)
				this.fetch()
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.share {
	max-width: 1280px;
	margin: 0 auto;
	.charts {
		h2{
			font-size: 18px;
			color: color(gray-500);
		}
	}
	.chart {
		width: 280px;
		height: 600px;
	}
	::v-deep.chart{
		margin: 0;
	}
}
::v-deep.element-container__content div{
	width: 100%;
}
.right {
	color: color(gray-600);
}
.no-data {
	margin-top: 20px;
}
.empty-filter {
	font-size: 16px;
		display: flex;
		justify-content: center;
		margin-top: 30px;
}

.chart-label {
	padding: 0 16px;
}
</style>
